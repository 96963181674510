import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "form-group form-check" }
const _hoisted_2 = ["aria-label", "id", "checked", "disabled", "required"]
const _hoisted_3 = ["for"]
const _hoisted_4 = {
  key: 1,
  class: "form-text text-muted"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      type: "checkbox",
      class: "form-check-input",
      "aria-label": _ctx.ariaLabel,
      id: _ctx.fieldId,
      checked: _ctx.checked,
      disabled: _ctx.disabled,
      required: _ctx.required,
      onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onUpdate($event.target.checked))),
      style: _normalizeStyle({ cursor: _ctx.disabled ? 'not-allowed' : 'pointer' }),
      ref: "input",
      onInvalid: _cache[1] || (_cache[1] = ($event: any) => (_ctx.validityMessage ? _ctx.setValidityMessage(_ctx.validityMessage) : null))
    }, null, 44, _hoisted_2),
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: "form-check-label",
          for: _ctx.fieldId
        }, _toDisplayString(_ctx.label), 9, _hoisted_3))
      : _createCommentVNode("", true),
    (_ctx.helpText)
      ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString(_ctx.helpText), 1))
      : _createCommentVNode("", true)
  ]))
}