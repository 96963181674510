import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = {
  key: 0,
  class: "w-100"
}
const _hoisted_3 = ["rows", "cols", "value", "placeholder", "disabled", "readonly", "maxlength", "aria-label"]
const _hoisted_4 = {
  key: 1,
  class: "form-text text-muted"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("textarea", {
      class: _normalizeClass(_ctx.readonly ? 'form-control-plaintext' : 'form-control'),
      rows: _ctx.rows,
      cols: _ctx.cols,
      value: _ctx.value,
      placeholder: _ctx.placeholder,
      disabled: _ctx.disabled,
      readonly: _ctx.readonly,
      maxlength: _ctx.maxlength,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateValue($event.target.value))),
      "aria-label": _ctx.ariaLabel,
      ref: "input",
      onInvalid: _cache[1] || (_cache[1] = ($event: any) => (_ctx.validityMessage ? _ctx.setValidityMessage(_ctx.validityMessage) : null))
    }, "\n    ", 42, _hoisted_3),
    (_ctx.helpText)
      ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString(_ctx.helpText), 1))
      : _createCommentVNode("", true)
  ]))
}