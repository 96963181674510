import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createBlock(_component_font_awesome_icon, {
    icon: _ctx.iconName,
    title: _ctx.$t('shared.delete'),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.confirmDelete(this.index))),
    class: "delete-icon"
  }, null, 8, ["icon", "title"]))
}