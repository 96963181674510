
import { PropType } from "@vue/runtime-core";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    /**
     * The index of the array item to delete
     */
    indexToDelete: {
      type: Number as PropType<number>,
      required: true,
    },
    /**
     * The array of items to delete from
     */
    items: {
      type: Array,
      required: true,
    },
    /**
     * The text of the array item to delete - used in the confirm dialog
     */
    itemText: {
      type: String as PropType<string>,
      required: true,
    },
    /**
     * The type of the array item to delete - used in the confirm dialog
     */
    itemTypeName: {
      type: String as PropType<string>,
      required: true,
    },
    /**
     * The substring length of the text of the array item to delete to display in the confirm dialog
     */
    itemTextSubstringLength: {
      type: Number as PropType<number>,
      required: true,
    },
    /**
     * The font awesome icon name
     */
    iconName: {
      type: String as PropType<string>,
      required: false,
      default: "trash-alt",
    },
  },
  methods: {
    confirmDelete(): void {
      const item = `${this.itemTypeName}: ${this.itemText.substring(
        0,
        this.itemTextSubstringLength
      )} ...`;

      const confirmMsg = this.$t("shared.confirm-delete-thing", { item: item });

      if (confirm(confirmMsg)) {
        this.items.splice(this.indexToDelete, 1);
      }
    },
  },
})
export default class DeleteIcon extends Vue {}
