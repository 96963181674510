
import { PropType } from "@vue/runtime-core";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    /**
     * The name of the radiobutton group
     */
    name: {
      type: String as PropType<string>,
      required: true,
    },
    /**
     * The value options
     */
    values: {
      type: Array,
      required: true,
    },
    /**
     * The current selected option
     */
    selected: {
      type: String as PropType<string>,
      required: true,
    },
    /**
     * If the radiobuttons should be stack on top of each other
     */
    verticalLayout: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: true,
    },
    /**
     * If title should be visible
     */
    showTitle: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: true,
    },
    /**
     * If fieldset should be visible
     */
    showFieldset: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: true,
    },
    /**
     * If the input is disabled
     */
    disabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    /**
     * The help text for the input
     */
    helpText: {
      type: String as PropType<string>,
      required: false,
    },
    /**
     * If the input is required
     */
    required: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    /**
     * Custom validity message
     */
    validityMessage: {
      type: String as PropType<string>,
      required: false,
    },
  },
  methods: {
    /**
     * Gets called when the value changes
     */
    onChange(value: string): void {
      this.setValidityMessage("");
      this.$emit("update:selected", value);
    },
    /**
     * Sets a custom validity message
     *
     * @param {string} message The message
     */
    setValidityMessage(message: string): void {
      this.$refs.input[0].setCustomValidity(message);
    },
  },
})
export default class RadioButtonGroup extends Vue {}
