
import { PropType } from "@vue/runtime-core";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    value: {
      type: String as PropType<string>,
      required: true,
    },
    ariaLabel: {
      type: String as PropType<string>,
      required: true,
    },
    label: {
      type: String as PropType<string>,
      required: false,
    },
    placeholder: {
      type: String as PropType<string>,
      required: false,
    },
    rows: {
      type: Number as PropType<number>,
      required: false,
      default: 4,
    },
    cols: {
      type: Number as PropType<number>,
      required: false,
      default: 40,
    },
    maxlength: {
      type: Number as PropType<number>,
      required: false,
      default: undefined, // omit this attribute if no value is supplied
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    readonly: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    helpText: {
      type: String as PropType<string>,
      required: false,
    },
    /**
     * Custom validity message
     */
    validityMessage: {
      type: String as PropType<string>,
      required: false,
    },
  },
  methods: {
    /**
     * Gets called when the user updates value
     *
     * @param {string} value The value
     */
    updateValue(value: string): void {
      this.setValidityMessage("");
      this.$emit("update:value", value);
    },
    /**
     * Sets a custom validity message
     *
     * @param {string} message The message
     */
    setValidityMessage(message: string): void {
      this.$refs.input.setCustomValidity(message);
    },
  },
})
export default class TextArea extends Vue {}
